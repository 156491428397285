import React from "react";
import { Link } from "gatsby";

import logo from '../images/logo.svg'

const Footer = () => {

    return (

        <>
        <footer className="px-4 py-8 bg-coolGray-100 text-coolGray-600 font-sans-serif">
            <div className="container flex flex-wrap items-center justify-center mx-auto space-y-4 sm:justify-between sm:space-y-0">
                <div className="flex flex-row pr-3 space-x-4 sm:space-x-8">
                    <div className="flex items-center justify-center flex-shrink-0 w-16 h-12 rounded-full bg-violet-600">
                    <img src={logo} alt="AJ Satori - Author" className="w-16" />
                    </div>
                    <ul className="flex flex-wrap items-center space-x-4 sm:space-x-8">
                        <li>
                            <Link to="/terms/" className="p-3 m-2">Terms of Use</Link>
                        </li>
                        <li>
                            <Link to="/privacy/" className="p-3 m-2">Privacy Policy</Link>
                        </li>
                        <li>
                            <Link to="/disclaimer/" className="p-3 m-2">Disclaimer</Link>
                        </li>
                    </ul>
                </div>
                <ul className="flex flex-wrap pl-3 space-x-4 sm:space-x-8">
                    {/* <li>
                        <a href="https://www.instagram.com/aj_satori/" target="_blank" rel="noreferrer">Instagram</a>
                    </li> */}
                    {/* <li>
                        <a href="#">Facebook</a>
                    </li> */}
                    {/* <li>
                        <a href="#">Twitter</a>
                    </li> */}
                </ul>
            </div>
            {/* <div className="mt-2 container mx-auto">
                <p className="text-right"><small><a href="https://www.rokitmedia.co.uk" target="_blank" rel="noreferrer" aria-label="Rokit Media Web Design">Website Designed by Rokit Media</a></small></p>
            </div> */}
        </footer>
        </>
    )
}

export default Footer