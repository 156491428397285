import React from 'react';
import { StaticImage } from "gatsby-plugin-image"

// import book from '../images/ajs-book-001-web.png';
// import bgSun from '../images/calm-with-sun-background.jpg'

const Hero = ({ location }) => {
    let isUK = false
    isUK = location.pathname.split('/')[1] === "uk"
    return (
        <>
            <section className="text-theme-primary-text font-sans-serif grid">
                <StaticImage style={{ gridArea: "1/1", maxHeight: "40rem" }} layout="constrained" alt="" src="../images/calm-with-sun-background.jpg" formats={["auto", "webp", "avif"]} />
                <div className="container flex flex-col justify-center p-6 mx-auto sm:py-12 lg:py-24 lg:flex-row lg:justify-between relative pt-16" style={{ gridArea: "1/1" }}>
                    <div className="flex flex-col justify-center p-6 text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
                        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-theme-accent" style={{ lineHeight: "1.2" }}>
                            Think Positive
                            <br className="inline-block" />
                            Be Happy
                            <br className="inline-block" />
                            Start Living
                        </h1>
                        <p className="mt-6 mb-8 text-lg sm:mb-12">
                            <span className="font-bold">A first hand guide and toolkit to positive thinking and well-being, promoting happier living.</span>
                            <br className="inline-block" />
                            <span className="font-bold">By AJ Satori.</span>
                        </p>
                        <div className="flex flex-col space-y-4 sm:items-center sm:justify-center sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-start">
                            {isUK ?
                                <a href="https://www.amazon.co.uk/Think-Positive-Happy-Start-Living/dp/B09JYCTTKZ/ref=as_li_ss_tl?_encoding=UTF8&qid=1634922033&sr=1-3&linkCode=ll1&tag=ajsatori-21&linkId=102c845894a32394f1e98c96e12fd1e4&language=en_GB"
                                    target="_blank" rel="noreferrer"
                                    className="relative px-8 py-4 ml-4 overflow-hidden uppercase rounded bg-theme-accent2 border-2 border-theme-primary-text text-theme-primary-text focus:outline-none hover:scale-105 transform ease-in-out duration-300">Order Your Copy Now
                                    <span className="absolute top-0 right-0 px-5 py-0 text-xs tracking-wider font-thin text-center uppercase origin-bottom-left transform rotate-45 -translate-y-full translate-x-1/3 bg-theme-accent1 border-2 border-theme-primary-text">New</span>
                                </a>
                                :
                                <a href="https://www.amazon.com/Think-Positive-Happy-Start-Living/dp/B09JYCTTKZ?_encoding=UTF8&qid=1635250319&sr=8-3&linkCode=ll1&tag=ajsatori-20&linkId=b3665a2c94404cb04e4f440dab979e2e&language=en_US&ref_=as_li_ss_tl"
                                    target="_blank" rel="noreferrer"
                                    className="relative px-8 py-4 ml-4 overflow-hidden uppercase rounded bg-theme-accent2 border-2 border-theme-primary-text text-theme-primary-text focus:outline-none hover:scale-105 transform ease-in-out duration-300">Order Your Copy Now
                                    <span className="absolute top-0 right-0 px-5 py-0 text-xs tracking-wider font-thin text-center uppercase origin-bottom-left transform rotate-45 -translate-y-full translate-x-1/3 bg-theme-accent1 border-2 border-theme-primary-text">New</span>
                                </a>
                            }
                        </div>
                    </div>
                    <div className="flex flex-col justify-center self-center p-6 mt-8 my-auto max-w-sm">
                        <StaticImage src="../images/ajs-book-001-web.png" alt="Think Positive, Be Happy, Start Living" placeholder="blurred" layout="constrained" />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Hero