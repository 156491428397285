import React from 'react';

import Header from './Header';
import Footer from "../components/Footer";

const Layout = ({children, location}) => {
    return (
        <>
        <Header location={location} />
        {children}
        <Footer />
        </>
    )
}

export default Layout