import React, { useState, useEffect } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"

import logo from '../images/logo.svg'

const Header = ({location}) => {

    let isHomeLocation = false
    if(location.pathname.split('/')[1]==="" || location.pathname.split('/')[1]==="uk") {
        isHomeLocation = true
    }
    const [click, setClick] = useState(false)
    const navClick = () => setClick(!click)

    const [scroll, setScroll] = useState(false)
    const changeNav = () => {
        if (window.scrollY >= 40) {
            setScroll(true)
        } else {
            setScroll(false)
        }
    }
    useEffect(() => {
        window.addEventListener("scroll", changeNav, { passive: true })
        return () => {
            window.removeEventListener("scroll", changeNav, { passive: true })
        }
    }, [])

    return (
        <>
            <header className={scroll ? "bg-theme-secondary-text p-4 font-sans-serif text-theme-primary fixed w-full shadow-md transform ease-in-out duration-500 z-50" : "p-4 font-sans-serif text-theme-primary fixed w-full transform ease-in-out duration-500 z-50"}>
                <div className={scroll ? "container flex justify-between h-6 mx-auto md:justify-center md:space-x-8" : "container flex justify-between h-16 mx-auto md:justify-center md:space-x-8"}>
                    <ul className="items-stretch hidden md:flex">
                        <li className="flex">
                            {isHomeLocation?
                            <AnchorLink href="#author" className="flex items-center px-4 -mb-1 border-b-2 border-transparent">Author</AnchorLink>
                            :
                            <a href="/#author" className="flex items-center px-4 -mb-1 border-b-2 border-transparent">Author</a>
                            }
                        </li>
                        <li className="flex items-center">
                            <span className="">
                                {isHomeLocation?
                                <AnchorLink href="#purchase" className="flex items-center px-2 -mb-1 py-1 rounded bg-theme-accent2 border-2 border-theme-primary-text text-theme-primary-text focus:outline-none hover:bg-theme-accent1 hover:scale-105 transform ease-in-out duration-300">Purchase</AnchorLink>
                                :
                                <a href="/#purchase" className="flex items-center px-2 -mb-1 py-1 rounded bg-theme-accent2 border-2 border-theme-primary-text text-theme-primary-text focus:outline-none hover:bg-theme-accent1 hover:scale-105 transform ease-in-out duration-300">Purchase</a>
                                }
                            </span>
                        </li>
                    </ul>
                    {isHomeLocation?
                    <AnchorLink href="#top" aria-label="Back to homepage" className="flex items-center p-2">
                        <img src={logo} alt="AJ Satori - Author" className={scroll ? "w-20 transform ease-in-out duration-500 z-50" : "w-36 transform ease-in-out duration-500 z-50"} />
                    </AnchorLink>
                    :
                    <a href="/#top"aria-label="Back to homepage" className="flex items-center p-2">
                        <img src={logo} alt="AJ Satori - Author" className={scroll ? "w-20 transform ease-in-out duration-500 z-50" : "w-36 transform ease-in-out duration-500 z-50"} />
                    </a>
                    }
                    <ul className="items-stretch hidden md:flex">
                        <li className="flex">
                            {isHomeLocation?
                            <AnchorLink href="#downloads" className="flex items-center px-4 -mb-1 border-b-2 border-transparent">Downloads</AnchorLink>
                            :
                            <a href="/#downloads" className="flex items-center px-4 -mb-1 border-b-2 border-transparent">Downloads</a>
                            }
                        </li>
                        <li className="flex">
                            {isHomeLocation?
                            <AnchorLink href="#contact" className="flex items-center px-4 -mb-1 border-b-2 border-transparent">Contact</AnchorLink>
                            :
                            <a href="/#contact" className="flex items-center px-4 -mb-1 border-b-2 border-transparent">Contact</a>
                            }
                        </li>
                    </ul>
                    <div className="flex md:hidden justify-end items-start">
                        <div
                            className={
                                click
                                    ? "mx-2 cursor-pointer outline-none hamburger active"
                                    : "mx-2 cursor-pointer outline-none hamburger"
                            }
                            onClick={navClick}
                            onKeyDown={navClick}
                            role="button"
                            tabIndex="0"
                            aria-label="Menu"
                        >
                            <span className={scroll ? "bar block h-1 bg-theme-accent1 w-7 rounded my-1 transform ease-in-out duration-200" : click ? "bar block h-1 bg-theme-accent1 w-7 rounded my-1 transform ease-in-out duration-200" : "bar block h-1 bg-theme-accent1 w-7 rounded my-1 transform ease-in-out duration-200"}></span>
                            <span className={scroll ? "bar block h-1 bg-theme-accent1 w-7 rounded my-1 transform ease-in-out duration-200" : click ? "bar block h-1 bg-theme-accent1 w-7 rounded my-1 transform ease-in-out duration-200" : "bar block h-1 bg-theme-accent1 w-7 rounded my-1 transform ease-in-out duration-200"}></span>
                            <span className={scroll ? "bar block h-1 bg-theme-accent1 w-7 rounded my-1 transform ease-in-out duration-200" : click ? "bar block h-1 bg-theme-accent1 w-7 rounded my-1 transform ease-in-out duration-200" : "bar block h-1 bg-theme-accent1 w-7 rounded my-1 transform ease-in-out duration-200"}></span>
                        </div>
                    </div>
                </div>
            </header>
            <div
                className={
                    click
                        ? "-mt-20 w-full translate-y-0 transform transition duration-500 ease-in-out md:hidden z-30 fixed"
                        : "-mt-20 w-full -translate-y-96 transform transition duration-500 ease-in-out md:hidden z-30 fixed"
                }
            >
                <div className="w-full bg-white shadow pt-36 font-sans-serif">
                    <ul className="flex items-stretch flex-col py-4 text-center text-lg">
                        <li className="flex justify-around py-2">
                            {isHomeLocation?
                            <AnchorLink href="#author" onClick={navClick} className="flex items-center px-4 -mb-1 border-b-2 border-transparent">Author</AnchorLink>
                            :
                            <a href="/#author" onClick={navClick} className="flex items-center px-4 -mb-1 border-b-2 border-transparent">Author</a>
                            }
                        </li>
                        <li className="flex justify-around py-2">
                            <span className="">
                                {isHomeLocation?
                                <AnchorLink href="#purchase" onClick={navClick} className="flex items-center px-2 -mb-1 py-1 rounded bg-theme-accent2 border-2 border-theme-primary-text text-theme-primary-text focus:outline-none hover:bg-theme-accent1 hover:scale-105 transform ease-in-out duration-300">Purchase</AnchorLink>
                                :
                                <a href="/#purchase" onClick={navClick} className="flex items-center px-2 -mb-1 py-1 rounded bg-theme-accent2 border-2 border-theme-primary-text text-theme-primary-text focus:outline-none hover:bg-theme-accent1 hover:scale-105 transform ease-in-out duration-300">Purchase</a>
                                }
                            </span>
                        </li>
                        <li className="flex justify-around py-2">
                            {isHomeLocation?
                            <AnchorLink href="#downloads" onClick={navClick} className="flex items-center px-4 -mb-1 border-b-2 border-transparent">Downloads</AnchorLink>
                            :
                            <a href="/#downloads" onClick={navClick} className="flex items-center px-4 -mb-1 border-b-2 border-transparent">Downloads</a>
                            }
                        </li>
                        <li className="flex justify-around py-2">
                            {isHomeLocation?
                            <AnchorLink href="#contact" onClick={navClick} className="flex items-center px-4 -mb-1 border-b-2 border-transparent">Contact</AnchorLink>
                            :
                            <a href="/#contact" onClick={navClick} className="flex items-center px-4 -mb-1 border-b-2 border-transparent">Contact</a>
                            }
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Header